import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { usePartyStore } from '../store/party'

export default defineNuxtRouteMiddleware((to) => {
  const store = usePartyStore()
  if (store.id && store.name) {
    const invId = to.params.inventory as string
    const inventoryIndex = store.inventories.findIndex((i) => i.id === invId)
    if (to.name === 'party-uuid-inventories') {
      return navigateTo(
        {
          name: 'party-uuid-inventories-inventory',
          params: { inventory: store.inventories[0].id },
        },
        { redirectCode: 301 }
      )
    } else if (
      to.name === 'party-uuid-inventories-inventory' &&
      inventoryIndex < 0
    ) {
      return navigateTo({
        name: 'party-uuid-inventories-inventory',
        params: { inventory: store.inventories[0].id },
      })
    }
  }
})
